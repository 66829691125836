<template>
  <div>
    <el-tooltip class="item" :content="loadingText"  effect="dark" placement="top">
      <i @click="exportData" class="el-icon-download" v-if="!loading"></i>
      <i class="el-icon-loading" v-if="loading"></i>
    </el-tooltip>
  </div>
</template>
<script>
import { export_json_to_excel } from '@/unit/Export2Excel.js'
export default {
  props: ['listData'],
  data() {
    return {
      loading: false,
      loadingText: '导出数据',
      outData: []
    }
  },
  mounted() {},
  methods: {
    exportData() {
      this.loading = true
      this.loadingText = '导出中'

      let tmpData = this.listData
      this.listData.map((item, index) => {
        tmpData[index].is_first_income_text =
          item.is_first_income == 1 ? '首次' : '再次'
        tmpData[index].seller_uid_text = this.$store.state.userList[
          item.seller_uid
        ][0]

        tmpData[index].income_time_section_text = this.getCfgData(
          item.income_time_section
        )
        tmpData[index].clue_source_text = this.getCfgData(item.clue_source)
        tmpData[index].liudang_text = '是'
        tmpData[index].wechat_text = item.wechat == '' ? '否' : '是'
        tmpData[index].is_curr_add_text = item.is_new_income == 1 ? '是' : '否'
        tmpData[index].is_last_add_text = item.is_new_income == 2 ? '是' : '否'
        tmpData[index].target_model_text = this.getCfgData(item.target_model)
        tmpData[index].displacement_text = this.getCfgData(item.displacement)
        tmpData[index].has_trial_text = this.isTrial(item)
        tmpData[index].has_trial2_text = this.isTrial2(item)
        tmpData[index].client_level_text = this.getCfgData(item.client_level)
        tmpData[index].buy_type_text = this.getCfgData(item.buy_type)
        tmpData[index].credit_type_text = this.getCfgData(item.credit_type)
        tmpData[index].old_valuator_text = item.valuator != '' ? '是' : '否'
        tmpData[index].region_text = this.getCfgData(item.region)
      })
      this.outData = tmpData

      if (this.outData.length > 0) {
        this.getExcel()
      } else {
        this.$message.error('当前无导出数据')
      }
    },
    getCfgData(val) {
      if (val == '') {
        return '-'
      }
      let i = 0

      if (typeof val == 'string') {
        i = parseInt(val)
      } else {
        i = val
      }

      return this.$store.state.cfgData[i][0]
    },

    getExcel() {
      require.ensure([], () => {
        const tHeader = [
          '客流性质',
          '销售顾问',
          '来访日期',
          '来访时间',
          '来访时间段',
          '离店时间',
          '接待时长',
          '客户人数',
          '客户姓名',
          '电话号码',
          '微信号',
          '线索渠道',
          '是否留档',
          '是否加微信',
          '是否当月新增用户',
          '是否上月留存客户',
          '跟踪次数',
          '关注车型',
          '型号',
          '首次来店客户试驾',
          '再次来店客户试驾',
          '意向级别',
          '交车客户',
          '购买类型',
          '金融需求',
          '旧车评估',
          '本市及周边区',
          '战败原因',
          '登记备注',
          '跟踪备注'
        ]
        const filterVal = [
          'is_first_income_text',
          'seller_uid_text',
          'income_date',
          'income_time_begin',
          'income_time_section_text',
          'income_time_end',
          'income_time_duration',
          'income_number',
          'realname',
          'mobile',
          'wechat',
          'clue_source_text',
          'liudang_text',
          'wechat_text',
          'is_curr_add_text',
          'is_last_add_text',
          'trace_count',
          'target_model_text',
          'displacement_text',
          'has_trial_text',
          'has_trial2_text',
          'client_level_text',
          'realname',
          'buy_type_text',
          'credit_type_text',
          'old_valuator_text',
          'region_text',
          'fall_memo',
          'demand_memo',
          'trace_memo'
        ]
        const list = this.outData
        const data = this.formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '月度台账')
        this.loading = false
        this.loadingText = '导出数据'
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    isTrial(item) {
      if (item.income_type == 1 && item.has_trial == 1) {
        return '是'
      } else {
        return '否'
      }
    },
    isTrial2(item) {
      if (item.income_type == 2 && item.has_trial == 1) {
        return '是'
      } else {
        return '否'
      }
    }
  }
}
</script>