<template>
  <div class="main" data-title="月度台账" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">月度台账</h2>
      </div>
    </div>
    <el-card class="main-content" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">月度进店列表<span class="totle">共{{listData.length}}条</span></div>
        <div class="jue-card-btns">
          <select-dep ref="selectDep" @setDeps="setDeps"></select-dep>
          <el-date-picker
            :clearable="false"
            @change="getNewData"
            end-placeholder="结束月份"
            format="yyyy年MM月"
            range-separator="-"
            start-placeholder="开始月份"
            style="margin-left:20px"
            type="monthrange"
            v-model="key"
            value-format="yyyy-MM"
          ></el-date-picker>
          <span class="jue-divider"></span>
          <export-info :listData="listData"></export-info>
        </div>
      </div>

      <div class="no-data" v-if="listData.length == 0">暂无数据</div>
      <div class="table-wrapper" v-else>
        <el-table :data="listData" :style="'width: '+$store.state.tableWidth" border class="table" height="615">
          <el-table-column label="客流性质" prop="is_first_income" width="70px">
            <template slot-scope="scope">{{scope.row.is_first_income == 1 ? "首次" : "再次"}}</template>
          </el-table-column>
          <el-table-column label="销售顾问" width="70px">
            <template slot-scope="scope">{{$store.state.userList[scope.row.seller_uid][0]}}</template>
          </el-table-column>
          <el-table-column label="来访日期" prop="income_date" width="95px"></el-table-column>
          <el-table-column label="来访时间" prop="income_time_begin" width="82px"></el-table-column>
          <el-table-column label="来访时间段" width="100px">
            <template slot-scope="scope">{{getCfgData(scope.row.income_time_section)}}</template>
          </el-table-column>
          <el-table-column label="离店时间" prop="income_time_end" width="70px"></el-table-column>
          <el-table-column label="接待时长" prop="income_time_duration" width="70px"></el-table-column>
          <el-table-column label="客户人数" prop="income_number" width="70px"></el-table-column>
          <el-table-column label="客户姓名" prop="realname" width="70px"></el-table-column>
          <el-table-column label="电话号码" prop="mobile" width="110px"></el-table-column>
          <el-table-column label="微信号" prop="wechat" width="70px"></el-table-column>
          <el-table-column label="线索渠道" width="70px">
            <template slot-scope="scope">{{getCfgData(scope.row.clue_source)}}</template>
          </el-table-column>
          <el-table-column label="是否留档" width="70px">是</el-table-column>
          <el-table-column label="是否加微信" prop="wechat" width="82px">
            <template slot-scope="scope">{{scope.row.wechat == '' ? "无" : "已加"}}</template>
          </el-table-column>
          <el-table-column label="是否当月新增用户" prop="is_first_income" width="117px">
            <template slot-scope="scope">{{scope.row.is_new_income == 1 ? "是" : "否"}}</template>
          </el-table-column>
          <el-table-column label="是否上月留存客户" width="117px">
            <template slot-scope="scope">{{scope.row.is_new_income == 2 ? "是" : "否"}}</template>
          </el-table-column>
          <el-table-column label="跟踪次数" prop="trace_count" width="70px"></el-table-column>
          <el-table-column label="关注车型" width="250px">
            <template slot-scope="scope">{{getCfgData(scope.row.target_model)}}</template>
          </el-table-column>
          <el-table-column label="型号" width="50px">
            <template slot-scope="scope">{{getCfgData(scope.row.displacement)}}</template>
          </el-table-column>
          <el-table-column label="首次来店客户试驾" prop="has_trial" width="117px">
            <template slot-scope="scope">{{isTrial(scope.row)}}</template>
          </el-table-column>
          <el-table-column label="再次来店客户试驾" width="117px">
            <template slot-scope="scope">{{isTrial2(scope.row)}}</template>
          </el-table-column>
          <el-table-column label="意向级别" width="70px">
            <template slot-scope="scope">{{getCfgData(scope.row.client_level)}}</template>
          </el-table-column>
          <el-table-column label="交车客户" prop="realname" width="70px">
            <template slot-scope="scope">{{scope.row.client_level == '184' ? "是" : "否"}}</template>
          </el-table-column>
          <el-table-column label="购买类型" width="70px">
            <template slot-scope="scope">{{getCfgData(scope.row.buy_type)}}</template>
          </el-table-column>
          <el-table-column label="金融需求" prop="credit_type" width="100px">
            <template slot-scope="scope">{{getCfgData(scope.row.credit_type)}}</template>
          </el-table-column>
          <el-table-column label="旧车评估" prop="valuator" width="70px">
            <template slot-scope="scope">{{scope.row.valuator != '' ? "是" : "否"}}</template>
          </el-table-column>
          <el-table-column label="本市及周边区" prop="region" width="117px">
            <template slot-scope="scope">{{getCfgData(scope.row.region)}}</template>
          </el-table-column>
          <el-table-column label="战败原因" prop="fall_memo" show-overflow-tooltip width="100px"></el-table-column>
          <el-table-column label="登记备注" prop="demand_memo" show-overflow-tooltip width="100px"></el-table-column>
          <el-table-column label="跟踪备注" prop="trace_memo" show-overflow-tooltip width="100px"></el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>
<script>
import { monthFormat } from '@/unit/unit.js'
import SelectDep from '@/components/chart/SelectDep.vue'
import ExportInfo from '@/components/ExportExcel/ExportInfo.vue'
export default {
  components: {
    SelectDep,
    ExportInfo
  },
  data() {
    return {
      key: [monthFormat(), monthFormat()],
      dept_ids: [],
      listData: []
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    setDeps(arr){
      this.dept_ids = arr
      this.getListData()
    },
    isCurrFirst(item) {
      //进店时间
      let tmp_t1 = item.income_date.split('-')
      let t1 = tmp_t1[0] + '-' + tmp_t1[1]

      if (t1 >= this.key[0] && t1 <= this.key[1]) {
        return true
      } else {
        return false
      }
    },
    isTrial(item) {
      if (item.income_type == 1 && item.has_trial == 1) {
        return '是'
      } else {
        return '否'
      }
    },
    isTrial2(item) {
      if (item.income_type == 2 && item.has_trial == 1) {
        return '是'
      } else {
        return '否'
      }
    },
    getNewData() {
      this.getListData()
    },
    getListData() {
      this.$axios
        .post('/statistic/data1', {
          begin_month: this.key[0],
          end_month: this.key[1],
          dept_ids: this.dept_ids
        })
        .then(res => {
          if (res.data.code == 200) {
            this.listData = res.data.data.list
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    getCfgData(val) {
      if (val == '' || val == 0) {
        return '-'
      }
      let i = 0

      if (typeof val == 'string') {
        i = parseInt(val)
      } else {
        i = val
      }

      return this.$store.state.cfgData[i][0]
      // return this.$store.state.cfgData[i][0] +' '+ this.$store.state.cfgData[i][1]
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
}
</style>